import React, { useState, useEffect } from "react";
import { Container, Col, Row, Image } from "react-bootstrap";
import SEO from "../../components/shared/seo";
import { FadeUpBox, FadeIn } from "../../components/shared/transitions";
import { useStaticQuery, graphql, Link } from "gatsby";
import { CaseStudyNav } from "../../components/shared/case-study-nav";
import { Controller, Scene } from "react-scrollmagic";
import LayoutCaseStudy from "../../components/layouts/layout-case-study";
import { Helmet } from "react-helmet";
import Swiper from "react-id-swiper";
import technicalIcon from "../../images/technical-icon.svg";
import videoVisibuy from "../../video/visibuy.mp4";
import videoPosterVisibuy from "../../images/video-poster-visibuy.png";
import timeIcon from "../../images/time-on-task-icon.svg";
import trainingIcon from "../../images/training.svg";
import paintbrushIcon from "../../images/paintbrush.svg";
import agileIcon from "../../images/agile.svg";
import BackgroundImage from "gatsby-background-image";

const swiperParams = {
  slidesPerView: "auto",
  spaceBetween: 15,
  freeMode: true,
  freeModeMomentumBounce: false,
  freeModeMomentum: false,
  freeModeMinimumVelocity: 0.2,
  longSwipesRatio: 0.8,
  loop: true,
  speed: 500,
  slidesOffsetAfter: 525,
  freeModeSticky: true,
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
};

function VisibuyPage({ location }) {
  // const { state = {} } = location;
  const [animate, setAnimate] = useState(false);
  const visibuyImages = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "visibuy-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      heroImage: file(
        relativePath: { eq: "visibuy-hero-image-case-studies.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      eaCaseStudy: file(relativePath: { eq: "ea-electricity.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      wordpressLogo: file(relativePath: { eq: "wordpress-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimate(true);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  });

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
          {
            "@context": "http://schema.org",
            "@type": "Organization",
            "name": "Diviv Group",
            "description": ".NET applications, Progress Sitefinity CMS, Drupal, GovCMS, Sitecore Experience Platform, Microsoft Sharepoint, Dynamics CRM and Azure Hosting support in Perth Australia. Coupled with our creative and user experience team we are able to provide expert consultation and resources on a wide range of digital development projects across many industries.",
            "url": "https://www.diviv.com.au",
            "image": "https://www.diviv.com.au/icons/icon-96x96.png",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "165 / 15 Aberdeen St",
              "addressLocality": "Perth",
              "addressRegion": "WA",
              "postalCode": "6000",
              "addressCountry": "AU"
            },
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "5",
              "ratingCount": "1"
            },
            "review": {
              "@type": "Review",
              "author": "Michael Johnson",
              "datePublished": "2020-03-09",
              "reviewBody": "Diviv is a solution based partner that delivers front and back end website solutions with innovation, professionalism and integrity. They are a great team and a pleasure to work with.",
              "reviewRating": {
                "@type": "Rating",
                "bestRating": "5",
                "ratingValue": "5",
                "worstRating": "1"
              }
            }
          }
          `}
        </script>
      </Helmet>
      <SEO
        title="Visibuy Website"
        keywords="Diviv our work case studies"
        description="Diviv has extensive experience resolving complex digital problems for businesses in a wide variety of industries. Refer to our case studies to understand how we have delivered successful business outcomes for businesses like yours."
      />
      <LayoutCaseStudy>
        <BackgroundImage
          className="fixed-background"
          fluid={visibuyImages.heroImage.childImageSharp.fluid}
        />
        <div className="page-layout pb-0">
          <Container>
            <Row>
              <Col sm={12} md={12} lg={8}>
                <div className="client-logo--lg mb-4">
                  <Image src={visibuyImages.logo.childImageSharp.fluid.src} />
                </div>
                <FadeUpBox pose={animate ? "enter" : "exit"}>
                  <FadeIn>
                    <h6>WordPress Website Build</h6>
                  </FadeIn>
                  <FadeIn duration={200}>
                    <h2>Visibuy Website</h2>
                  </FadeIn>
                  <FadeIn duration={200}>
                    <p>
                      <strong>
                        PHARMACY 777 IS A LEADER IN THE RETAIL PHARMACY SECTOR
                      </strong>
                    </p>
                    <p>
                      Pharmacy 777 provides innovative and quality
                      pharmaceutical services to customers across Australia. The
                      Pharmacy 777 team entrusted Diviv with the challenge of
                      developing their brand-new campaign website Visibuy. The
                      website is used to promote brands and products, with the
                      aim of providing exclusive deals, special offers and
                      competitive trading terms via their membership program to
                      pharmacies across the nation.
                    </p>

                    <p>
                      At Diviv we're always up for a challenge and were happy to
                      collaborate with the Pharmacy 777 team to deliver a
                      high-quality WordPress solution that meets all of their
                      requirements.
                    </p>
                  </FadeIn>
                </FadeUpBox>
              </Col>

              <Col sm={12} md={12} lg={4}>
                <FadeUpBox pose={animate ? "enter" : "exit"}>
                  <FadeIn>
                    <div className="client-meta">
                      Discover what else Visibuy is up to by visiting their
                      website:
                      <div className="client-meta__link">
                        <a href="https://www.visibuy.com.au/" target="_blank">
                          Visibuy
                        </a>
                      </div>
                    </div>
                  </FadeIn>
                </FadeUpBox>
              </Col>
            </Row>
          </Container>

          <Controller>
            <Scene
              triggerElement="carousel01"
              reverse={false}
              duration={2000}
              offset={200}
            >
              {(progress, event) => (
                <section id="carousel01">
                  <Container>
                    <Row
                      id="challenge"
                      className="carousel-section technical-challenge-slider-active"
                    >
                      <Col lg={6} sm={12} className="col-left">
                        <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                          <FadeIn>
                            <h2>DEFINE EACH CHALLENGE</h2>
                            <p>
                              Every new website build has its share of
                              challenges. At Diviv, we use a project review and
                              feedback loop to continuously learn from previous
                              projects, overtime this helps make us better
                              developers, increases our team’s efficiency and
                              maintain a quality outcome for our customers.
                            </p>

                            <p>
                              Through our close and collaborative project
                              delivery method we were able to quickly understand
                              Pharmacy 777’s goals. By using learnings from our
                              continuous improvement model, we're able to
                              deliver a high-quality product in an extremely
                              tight timeframe. Risks were identified for known
                              challenges upfront and through regular
                              communication with the Pharmacy 777 team, we were
                              able to resolve inflight quickly to keep us on
                              track and meet the launch deadline.
                            </p>
                          </FadeIn>
                        </FadeUpBox>
                      </Col>

                      <Col lg={6} sm={12} className="col-right">
                        <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                          <FadeIn>
                            <div className="carousel-slider-container">
                              <div className="carousel__outer swiper1">
                                <Swiper {...swiperParams}>
                                  <div className="swiper-slide">
                                    <div className="slide__icon">
                                      <img
                                        src={technicalIcon}
                                        alt="Technical icon"
                                      />
                                    </div>
                                    <div className="slide__number">01</div>
                                    <div className="slide__title">
                                      Challenge
                                    </div>

                                    <div className="slide__sub-title">
                                      Time challenge
                                    </div>

                                    <p>
                                      With just over 30 days to complete this
                                      project, time was of the essence. We set
                                      up a small autonomous development team
                                      with open and regular customer
                                      communication and collaboration. This
                                      meant we could quickly execute project
                                      decisions and deal with issues, keeping us
                                      on track.
                                    </p>
                                  </div>

                                  <div className="swiper-slide">
                                    <div className="slide__icon">
                                      <img
                                        src={technicalIcon}
                                        alt="Technical icon"
                                      />
                                    </div>
                                    <div className="slide__number">02</div>
                                    <div className="slide__title">
                                      Challenge
                                    </div>

                                    <div className="slide__sub-title">
                                      Training challenge
                                    </div>
                                    <p>
                                      One of the driving factors of WordPress as
                                      a CMS is the user-friendly interface that
                                      allows content editors to update content
                                      easily. WordPress has a shorter learning
                                      curve with minimal training required and
                                      is extensively documented should a content
                                      editor need to research how to complete a
                                      specific task.
                                    </p>
                                  </div>
                                  <div className="swiper-slide">
                                    <div className="slide__icon">
                                      <img
                                        src={technicalIcon}
                                        alt="Technical icon"
                                      />
                                    </div>
                                    <div className="slide__number">03</div>
                                    <div className="slide__title">
                                      Challenge
                                    </div>

                                    <div className="slide__sub-title">
                                      Creative flexibility
                                    </div>
                                    <p>
                                      As content editors become more
                                      knowledgeable and HTML skills increase,
                                      WordPress allows for greater control
                                      providing content-editors more flexibility
                                      to customise page layouts whilst
                                      preserving the look and feel of the
                                      website.
                                    </p>
                                  </div>
                                  <div className="swiper-slide">
                                    <div className="slide__icon">
                                      <img
                                        src={technicalIcon}
                                        alt="Technical icon"
                                      />
                                    </div>
                                    <div className="slide__number">04</div>
                                    <div className="slide__title">
                                      Challenge
                                    </div>

                                    <div className="slide__sub-title">
                                      Custom content
                                    </div>
                                    <p>
                                      To maximise time and cost savings we
                                      recommend using as many out of the box
                                      WordPress components as possible. Where
                                      necessary, we provided custom developed
                                      blocks to extend on functionality to
                                      specifically meet business requirements.
                                    </p>
                                  </div>
                                </Swiper>
                                <div className="swiper-pagination swiper-pagination1"></div>
                              </div>
                            </div>
                          </FadeIn>
                        </FadeUpBox>
                      </Col>
                    </Row>
                  </Container>
                </section>
              )}
            </Scene>

            <Scene
              triggerElement="#discovery"
              reverse={false}
              duration={2000}
              offset={200}
            >
              {(progress, event) => (
                <section id="discovery">
                  <Container>
                    <Row>
                      <Col lg={5} sm={12} className="cms-logos">
                        <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                          <FadeIn>
                            <img
                              src={
                                visibuyImages.wordpressLogo.childImageSharp
                                  .fluid.src
                              }
                              alt="Wordpress Logo"
                            />
                          </FadeIn>
                        </FadeUpBox>
                      </Col>

                      <Col lg={7} sm={12} className="desc">
                        <div>
                          <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                            <FadeIn>
                              <h2>Discovery</h2>
                              <p>
                                Finding the right CMS fit is can be a difficult
                                challenge for new websites and even more
                                challenging for new clients. We proposed
                                WordPress CMS based on understanding the
                                high-level requirements and because we knew the
                                timeline would be tight. WordPress ticked all
                                the boxes in this case, it would facilitate the
                                ease and speed of content entry along with
                                meeting our development goals.
                              </p>
                              <p>
                                For Visibuy we designed and implemented custom
                                post types with accompanying blocks. This
                                approach simplified content entry and provided
                                Pharmacy 777 drag and drop components that can
                                be placed anywhere throughout the site,
                                maximising flexibility.
                              </p>
                            </FadeIn>
                          </FadeUpBox>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </section>
              )}
            </Scene>
          </Controller>

          <Controller>
            <Scene
              triggerElement="carousel02"
              reverse={false}
              duration={2000}
              offset={200}
            >
              {(progress, event) => (
                <section id="carousel02">
                  <Container>
                    <Row
                      id="technicalProcess"
                      className="carousel-section technical-slider-active"
                    >
                      <Col lg={6} sm={12} className="col-left">
                        <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                          <FadeIn>
                            <h2>Technical </h2>
                            <p>
                              With a short time frame the project needed to run
                              as smooth and efficient as possible. We adopted an
                              agile-like project delivery methodology, using
                              weekly sprints with close customer collaboration
                              to keep us on track and deliver on time.
                            </p>
                          </FadeIn>
                        </FadeUpBox>
                      </Col>

                      <Col lg={6} sm={12} className="col-right">
                        <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                          <FadeIn>
                            <div className="carousel-slider-container">
                              <div className="carousel__outer swiper3">
                                <Swiper {...swiperParams}>
                                  <div className="swiper-slide">
                                    <div className="slide__icon">
                                      <img
                                        src={technicalIcon}
                                        alt="Technical icon"
                                      />
                                    </div>
                                    <div className="slide__number">01</div>
                                    <div className="slide__title">
                                      Technical process
                                    </div>

                                    <div className="slide__sub-title">
                                      Defining the process
                                    </div>

                                    <p>
                                      Defining a Process Adopting a workflow and
                                      process that would be carried throughout
                                      the short lifespan of the project. In
                                      doing this we simplified our development
                                      process and introduced some aspects of
                                      agile.
                                    </p>
                                  </div>

                                  <div className="swiper-slide">
                                    <div className="slide__icon">
                                      <img
                                        src={technicalIcon}
                                        alt="Technical icon"
                                      />
                                    </div>
                                    <div className="slide__number">02</div>
                                    <div className="slide__title">
                                      Technical process
                                    </div>

                                    <div className="slide__sub-title">
                                      Agile-Like Methodology – Getting things
                                      moving
                                    </div>
                                    <p>
                                      By working in a small autonomous project,
                                      structuring the team and an agile-like
                                      process accommodated fast development and
                                      quick decision making. This was key to
                                      working within this short time frame. Open
                                      regular and transparent communication with
                                      the customer means there were no surprises
                                      and sped up the feedback loop to prevent
                                      any possible miscommunication that could
                                      cause rework.
                                    </p>
                                  </div>
                                </Swiper>
                                <div className="swiper-pagination swiper-pagination3"></div>
                              </div>
                            </div>
                          </FadeIn>
                        </FadeUpBox>
                      </Col>
                    </Row>
                  </Container>
                </section>
              )}
            </Scene>
          </Controller>

          <Controller>
            <Scene
              triggerElement="#solution"
              reverse={false}
              duration={2000}
              offset={200}
            >
              {(progress, event) => (
                <section
                  id="solution"
                  className="bg-cyan"
                  style={{ background: "none" }}
                >
                  <Container>
                    <Row>
                      <Col lg={4} sm={12} className="title">
                        <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                          <FadeIn>
                            <h2>How we solve your technical problem</h2>
                          </FadeIn>
                        </FadeUpBox>
                      </Col>

                      <Col lg={8} sm={12} className="desc">
                        <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                          <FadeIn>
                            <p>
                              We proposed a comprehensive solution to address
                              all of Pharmacy 777’s challenges and
                              requirements using the WordPress CMS. All pages
                              use as much of the default functionality already
                              delivered by WordPress. This is further enhanced
                              with our custom developed widgets, complemented by
                              using some tried, tested, and trusted plugins from
                              the WordPress vast plugin library.
                            </p>
                            <p>
                              As with any client, time and budget are important
                              considerations for any project. Using the value
                              from our continuous learning processes and our
                              agile-like project methodology, we maintain the
                              delivery of high quality solutions for our
                              customers.
                            </p>

                            <p>
                              No matter how big or small the job, our team at
                              Diviv want to make the entire process as simple
                              and pain free.{" "}
                              <Link
                                to="../../contactus"
                                className="inline-link-white"
                              >
                                Speak with us today
                              </Link>{" "}
                              to ask how we can help you with your next project!
                            </p>
                          </FadeIn>
                        </FadeUpBox>
                      </Col>
                    </Row>
                  </Container>
                </section>
              )}
            </Scene>
          </Controller>

          <Controller>
            <Scene
              triggerElement="#solution-video"
              reverse={false}
              duration={2000}
              offset={200}
            >
              {(progress, event) => (
                <section id="solution-video">
                  <Container className="py-5">
                    <Row>
                      <Col lg={12} sm={12}>
                        <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                          <FadeIn>
                            <h2>FINAL SOLUTION</h2>

                            <video
                              playsInline
                              controls
                              width="1078"
                              height="810"
                              poster={videoPosterVisibuy}
                            >
                              <source src={videoVisibuy} type="video/mp4" />
                            </video>
                          </FadeIn>
                        </FadeUpBox>
                      </Col>
                    </Row>
                  </Container>
                </section>
              )}
            </Scene>
          </Controller>
        </div>
        {/*  <Scene
                    triggerElement="#bottom-section"
                    reverse={false}
                    duration={2000}
                    offset={200}
                >
                    {(progress, event) => (
                        <section className="quote-wrapper">
                            <Container>
                                <QuoteBlock
                                    author="Michael Johnson"
                                    authorPosition="Senior Business Analyst"
                                    quoteText="Diviv is a solution based partner that delivers
                              front and back end website solutions with
                              innovation, professionalism and integrity. They
                              are a great team and a pleasure to work with."
                                    clientLogoUrl={visibuyImages.logo.childImageSharp.fluid.src}
                                />
                            </Container>
                        </section>
                    )}
                </Scene> */}

        <CaseStudyNav
          nextUrl="/our-work/energy-action"
          nextImage={visibuyImages.eaCaseStudy.childImageSharp.fluid.src}
          nextTitle="Energy Action Portal"
        />
      </LayoutCaseStudy>
    </>
  );
}

export default VisibuyPage;
